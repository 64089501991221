// project import
import dashboard from './dashboard';
// import pages from './pages';
// import utilities from './utilities';
// import support from './support';
import config from './config';
import create from './create';

// ==============================|| MENU ITEMS ||============================== //

const menuItems = {
  // items: [dashboard, pages, utilities, support]
  items: [dashboard, config, create]
};

export default menuItems;
