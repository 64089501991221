// assets
import { AppstoreAddOutlined, BgColorsOutlined, ExperimentOutlined, PictureOutlined, VideoCameraOutlined } from '@ant-design/icons';

// icons
const icons = {
  BgColorsOutlined,
  AppstoreAddOutlined,
  ExperimentOutlined,
  PictureOutlined,
  VideoCameraOutlined
};

// ==============================|| MENU ITEMS - UTILITIES ||============================== //

const utilities = {
  id: 'create',
  title: 'Editar',
  type: 'group',
  children: [
    {
      id: 'create-social',
      title: '3D Studio',
      type: 'item',
      url: '/studio',
      icon: icons.ExperimentOutlined
    }
    // ,
    // {
    //   id: 'create-banner',
    //   title: 'Banners',
    //   type: 'item',
    //   url: '/banners',
    //   icon: icons.PictureOutlined
    // },
    // {
    //   id: 'create-social',
    //   title: 'Social Video',
    //   type: 'item',
    //   url: '/social-video',
    //   icon: icons.VideoCameraOutlined
    // } 
  ]
};

export default utilities;
