// UserContext.js
import React, { createContext, useReducer, useContext } from 'react';

// Initial state for the context
const initialState = {
  userID: '',
  storeName: '',
  token: '',
  email: '',
  userName: '',
  stores: []
};

// Define the actions for the context
const actionTypes = {
  SET_USER: 'SET_USER'
};

// Reducer function to handle state changes
const reducer = (state, action) => {
  switch (action.type) {
    case actionTypes.SET_USER:
      return { ...state, ...action.payload };
    default:
      return state;
  }
};

// Create the context
const UserContext = createContext();

// Create a provider component to wrap your app
// eslint-disable-next-line react/prop-types
export const UserProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialState);

  // Actions to update the context state
  const setUser = (userData) => {
    dispatch({ type: actionTypes.SET_USER, payload: userData });
  };

  // Value object to be passed to the provider
  const value = {
    state,
    setUser
  };

  return <UserContext.Provider value={value}>{children}</UserContext.Provider>;
};

// Custom hook to access the context in functional components
export const useUser = () => {
  const context = useContext(UserContext);
  if (!context) {
    throw new Error('useUser must be used within a UserProvider');
  }
  return context;
};
