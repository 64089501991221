// material-ui
// import { useTheme } from '@mui/material/styles';

/**
 * if you want to use image instead of <svg> uncomment following.
 *
 * import logoDark from 'assets/images/logo-dark.svg';
 * import logo from 'assets/images/logo.svg';
 *
 */
import LogoPop3D from 'assets/images/shared/pop3d.png';
import { ReactComponent as LogoSVG } from 'assets/images/shared/dbh-futuriza-logo.svg';


// ==============================|| LOGO SVG ||============================== //

const Logo = () => {
  // const theme = useTheme();

  return (
    /**
     * if you want to use image instead of svg uncomment following, and comment out <svg> element.
     *
     * <img src={logo} alt="Mantis" width="100" />
     *
     */
    <>
      {/* <img src={LogoPop3D} alt="POP 3D" width="140" /> */}
      <LogoSVG height={150} width={300} fill='orange'/>
    </>
  );
};

export default Logo;
