// assets
import { AppstoreAddOutlined, BgColorsOutlined } from '@ant-design/icons';

// icons
const icons = {
  BgColorsOutlined,
  AppstoreAddOutlined
};

// ==============================|| MENU ITEMS - UTILITIES ||============================== //

const utilities = {
  id: 'configuration',
  title: 'Configurar',
  type: 'group',
  children: [
    {
      id: 'config-product',
      title: 'Products',
      type: 'item',
      url: '/products',
      icon: icons.AppstoreAddOutlined
    }
    // {
    //   id: 'config-background',
    //   title: 'HDR Backgrounds',
    //   type: 'item',
    //   url: '/backgrounds',
    //   icon: icons.BgColorsOutlined
    // }
  ]
};

export default utilities;
